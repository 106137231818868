module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"C:\\Users\\Paulius\\Documents\\GatsbyLayout/src/intl","languages":["lt","en"],"defaultLanguage":"lt","redirect":true,"redirectComponent":"C:\\Users\\Paulius\\Documents\\GatsbyLayout\\src\\components\\Redirect.js"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"C:\\Users\\Paulius\\Documents\\GatsbyLayout/src/cms/cms.js"},
    }]
